<template>
  <v-flex>
    <v-dialog v-model="showConfirm" max-width="600px">
      <v-card>
        <v-card-title class="headline">
          {{ txtMessages[btnOption] }}
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-spacer></v-spacer>

        <v-card-text v-if="btnOption == 'txtDeleteProductTagged'">
          Se eliminaran los productos que esten creados en los dropshippers
          y que tengan la tag NODROPHIPPING.
          Una vez iniciado el proceso no se puede detener.
          El proceso se quedara ejecutando en 2do plano y se le notificara
          por correo una vez que finalice la ejecucion
        </v-card-text>
        <v-card-text v-if="btnOption == 'txtInactiveProducts'">
          Si todas las variantes del producto estan inactivas se archiva el producto
          Si son solo algunas variantes que estan inactivas se eliminan las variantes.

          Un sku puede estar inactivo porque fue eliminado en Shopify,
          o porque no esta activo en shopify,
          o porque tiene la tag NODROPHIPPING y antes no la tenia
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="syncOption(btnOption)">
            {{ txtMessages[btnOption] }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <notify-custom :showNotify="show" :type="tipoNotify"
    :message="msgNotify" :icontext="iconNotify" :color="color">
    </notify-custom>
    <v-row v-if="userLogged.rol == 'dev'" align="center" justify="space-around" class="mb-4">
        <v-btn color="error" @click="showConfirm = true;
        btnOption = 'txtDeleteProductTagged'">
          {{ txtMessages.txtDeleteProductTagged }}
        </v-btn>
        <v-btn color="primary" @click="showConfirm = true;
        btnOption = 'txtInactiveProducts'">
          {{ txtMessages.txtInactiveProducts }}
        </v-btn>
    </v-row>
    <v-data-table :page="page" :pageCount="numberOfPages"
    :options.sync="options" :server-items-length="totalItems"
      :loading="loading" :headers="headers" :items="objetos"
      :footer-props="propsTextFooterDataTable"
      class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>PLN Variants</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search"
          append-icon="search" :label="txtMessages.txtSearch"
            single-line hide-details @change="listar()">
          </v-text-field>
          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>
          <vue-excel-xlsx :data="objExcel" :columns="columns"
          :file-name="'logsList'" :file-type="'xlsx'"
            :sheet-name="'logs'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="ma-2 white--text" fab small v-bind="attrs" v-on="on">
                  <v-icon dark>
                    file_download
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ txtMessages.txtExport }}</span>
            </v-tooltip>
          </vue-excel-xlsx>
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar-title>
            <small>
            </small>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        <td>
          {{ $moment(item.created_at).format('YYYY-MM-DD HH:MM') }}
        </td>
      </template>
      <template v-slot:item.updated_at="{ item }">
        <td>
          {{ $moment(item.updated_at).format('YYYY-MM-DD HH:MM') }}
        </td>
      </template>
      <template v-slot:item.active="{ item }">
        <td>
          <div v-if="item.active">
            <v-chip color="green" dark> {{ txtMessages.txtActive }} </v-chip>
          </div>
          <div v-else>
            <v-chip color="red" dark> {{ txtMessages.txtInactive }} </v-chip>
          </div>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="listar()">
          {{ txtMessages.txtReset }}
        </v-btn>
      </template>
    </v-data-table>
  </v-flex>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      btnOption: '',
      showConfirm: false,
      txtMessages: langMsg,
      propsTextFooterDataTable: {
        itemsPerPageText: 'Rows per page',
        itemsPerPageAllText: 'All',
      },
      dialog: false,
      search: '',
      objetos: [],
      objExcel: [],
      dialogDelete: false,
      page: 1,
      numberOfPages: 10,
      options: {},
      totalItems: 0,
      headers: [
        {
          text: langMsg.txtCreationDate, align: 'start', value: 'created_at', sortable: false,
        },
        {
          text: langMsg.txtUpdateDate, align: 'start', value: 'updated_at', sortable: false,
        },
        { text: 'sku', value: 'sku', sortable: false },
        // { text: langMsg.txtVariantId, value: 'pln_id', sortable: false },
        // { text: langMsg.txtProductId, value: 'product_id', sortable: false },
        // { text: langMsg.txtInventoryItemId, value: 'inventory_item_id', sortable: false },
        { text: 'status', value: 'active', sortable: false },
      ],
      columns: [
        { label: langMsg.txtCreationDate, field: 'created_at' },
        {
          text: langMsg.txtUpdateDate, align: 'start', value: 'updated_at', sortable: false,
        },
        { label: 'sku', field: 'sku' },
        // { label: langMsg.txtVariantId, field: 'pln_id' },
        // { label: langMsg.txtProductId, field: 'product_id' },
        // { label: langMsg.txtInventoryItemId, field: 'inventory_item_id' },
        { label: 'status', field: 'active' },
      ],
      // eslint-disable-next-line
      data: {},
      // --- events data ---
      loading: false,
      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',
      userLogged: null,
    };
  },
  computed: {
  },
  watch: {
    dialog(val) {
      const exp = val || this.close();
      return exp;
    },
    dialogDelete(val) {
      const exp = val || this.closeDelete();
      return exp;
    },
    options: {
      handler() {
        this.listar();
      },
    },
    deep: true,
  },
  created() {
    this.userLogged = JSON.parse(localStorage.getItem('user'));
    axios.defaults.headers.common.authorization = this.$store.state.token;
    this.$store.commit('SET_LAYOUT', 'principal-layout');
    if (axios.defaults.headers.common.authorization) this.listar();
  },

  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    getTypeLog(type) {
      if (!type === null || type === 'none') return false;
      let result = null;
      switch (type) {
        case 'Info':
          result = 0;
          break;
        case 'Warning':
          result = 1;
          break;
        case 'Error':
          result = 2;
          break;
        default:
          result = false;
      }
      return result;
    },
    getTypeLogReverse(type) {
      let result = null;
      switch (type) {
        case 0:
          result = 'Info';
          break;
        case 1:
          result = 'Warning';
          break;
        case 2:
          result = 'Error';
          break;
        default:
          result = '-';
      }
      return result;
    },
    syncOption(option) {
      if (option === 'txtDeleteProductTagged') {
        const me = this;
        me.loading = true;
        me.objetos = [];
        axios.get('product/all-not-allowed').then((res) => {
          me.loading = false;
          this.show = true;
          this.tipoNotify = 'success';
          this.msgNotify = res.data;
          this.color = 'green';
        }).catch((err) => {
          this.show = true;
          this.tipoNotify = 'error';
          this.msgNotify = err?.response?.message;
          this.color = 'data';
        });
      }
      if (option === 'txtInactiveProducts') {
        const me = this;
        me.loading = true;
        me.objetos = [];
        axios.get('product/all-inactives').then((res) => {
          me.loading = false;
          this.show = true;
          this.tipoNotify = 'success';
          this.msgNotify = res.data;
          this.color = 'green';
        }).catch((err) => {
          this.show = true;
          this.tipoNotify = 'error';
          this.msgNotify = err?.response?.message;
          this.color = 'data';
        });
      }
    },
    listar() {
      const me = this;
      me.loading = true;
      const { page, itemsPerPage } = me.options;
      const items = itemsPerPage || 10;
      const pageNumber = page || 1;
      this.idDropshipper = this.$route.params.id;
      me.objetos = [];
      let urlConsult = `variant/provider/getmany?limit=${items}&page=${pageNumber}`;
      const type = me.getTypeLog(this.typeLogs);
      const title = me.titleLogs || false;
      const search = me.search !== '' ? me.search : false;
      if (type || type === 0) urlConsult = `${urlConsult}&type=${type}`;
      if (title) urlConsult = `${urlConsult}&title=${title}`;
      if (search) urlConsult = `${urlConsult}&search=${search}`;
      axios.get(urlConsult).then((res) => {
        me.loading = false;
        me.objetos = res.data.body.docs;
        me.objExcel = res.data.body.docs.map((a) => {
          const doc = { ...a };
          doc.created_at = moment(a.created_at).format('YYYY-MM-DD HH:MM');
          doc.updated_at = moment(a.updated_at).format('YYYY-MM-DD HH:MM');
          return doc;
        });
        me.totalItems = res.data.body.totalDocs;
        me.numberOfPages = res.data.body.pages;
      });
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      const empty = '';
      this.nameDropshipper = empty;
    },
  },
};
</script>
