<template>
  <v-flex>
    <notify-custom :showNotify="show" :type="tipoNotify"
    :message="msgNotify" :icontext="iconNotify" :color="color">
    </notify-custom>
    <v-data-table :page="page" :pageCount="numberOfPages"
    :options.sync="options" :server-items-length="totalItems"
      :loading="loading" :headers="headers" :items="objetos"
      sort-by="created_at" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ txtMessages.txtOrdersNoSync }} {{ nameDropshipper }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search"
          append-icon="search" :label="txtMessages.txtSearch"
            single-line hide-details @change="listar()">
          </v-text-field>
          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>
          <vue-excel-xlsx :data="objExcel" :columns="columns"
          :file-name="'ordersDropshipper'" :file-type="'xlsx'"
            :sheet-name="'orders'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="ma-2 white--text" fab small v-bind="attrs" v-on="on">
                  <v-icon dark>
                    file_download
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ txtMessages.txtExport }}</span>
            </v-tooltip>
          </vue-excel-xlsx>
          <v-dialog v-model="showModalSync" max-width="600px">
            <v-card>
              <v-card-title class="headline">
                RESULT
              </v-card-title>
              <v-card-text>MSG: {{ resultSync.message }}</v-card-text>
              <v-card-text>TOTAL ORDER {{ resultSync.totalOrder }}</v-card-text>
              <v-card-text>SKUS {{ resultSync.skusOrder }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        <td>
          {{ $moment(item.created_at).format('YYYY-MM-DD HH:MM') }}
        </td>
      </template>
      <template v-slot:item.register_logs="{ item }">
        <td>
          <h5>{{ item.register_logs.length >= 1 ? item.register_logs[0].date : '' }}</h5>
          {{ item.register_logs.length >= 1 ? item.register_logs[0].content : '' }}
          ({{ item.register_logs.length }})
        </td>
      </template>
      <template v-slot:item.shipping_lines="{ item }">
        <td>
          <p v-for="v in item.shipping_lines" :key="v.number">{{ v.code }}</p>
        </td>
      </template>
      <template v-slot:item.options="{ item }">
        <template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="#ff9800" class="mr-2"
              v-bind="attrs" v-on="on" :loading="item.loading"
                :disabled="item.loading" @click="syncOrderDropshipper(item.id)">
                <v-icon> mdi-sync
                </v-icon>
              </v-btn>
            </template>
            <span>{{ txtMessages.txtSyncOrder }}</span>
          </v-tooltip>
        </template>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="listar()">
          {{ txtMessages.txtReset }}
        </v-btn>
      </template>
    </v-data-table>
  </v-flex>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      txtMessages: langMsg,
      dialog: false,
      showModalSync: false,
      resultSync: {},
      search: '',
      objetos: [],
      objExcel: [],
      dialogDelete: false,
      page: 1,
      numberOfPages: 10,
      options: {},
      totalItems: 0,
      headers: [
        {
          text: langMsg.txtLastTry, align: 'start', value: 'created_at', sortable: false,
        },
        { text: 'order name Dropshipper', value: 'name', sortable: false },
        { text: 'order id', value: 'id', sortable: false },
        { text: langMsg.txtOrderPriceDropshipper, value: 'total_price', sortable: false },
        { text: langMsg.txtOrderShippingLine, value: 'shipping_lines', sortable: false },
        { text: langMsg.txtOrderNote, value: 'note', sortable: false },
        { text: langMsg.logs, value: 'register_logs', sortable: false },
        { text: langMsg.txtOptions, value: 'options', sortable: false },
      ],
      columns: [
        { label: langMsg.txtLastTry, field: 'created_at' },
        { label: langMsg.txtOrderName, field: 'name' },
        { label: langMsg.txtOrderIdProvider, field: 'id' },
        { label: langMsg.txtOrderPriceDropshipper, field: 'total_price' },
        { label: langMsg.txtOrderShippingLine, field: 'shipping_lines' },
        { label: langMsg.txtOrderNote, field: 'note' },
      ],
      // eslint-disable-next-line
      nameDropshipper: '',
      nameConfig: '',
      idDropshipper: '',
      data: {},
      // --- events data ---
      loading: false,

      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',
    };
  },
  computed: {
  },
  watch: {
    dialog(val) {
      const exp = val || this.close();
      return exp;
    },
    dialogDelete(val) {
      const exp = val || this.closeDelete();
      return exp;
    },
    options: {
      handler() {
        this.listar();
      },
    },
    deep: true,
  },
  created() {
    this.$store.commit('SET_LAYOUT', 'principal-layout');
  },
  mounted() {
    axios.defaults.headers.common.authorization = this.$store.state.token;
    this.idDropshipper = this.$route.params.id;
    if (this.$store.state.token) {
      this.getOne(this.idDropshipper);
      this.listar();
    }
  },

  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    syncOrderDropshipper(idOrder) {
      const me = this;
      this.loading = true;
      // eslint-disable-next-line
      axios.get(`order/sync/single/${this.idDropshipper}/${idOrder}`).then((response) => {
        if (response.data.length > 0) {
          this.showModalSync = true;
          const orderRes = response.data.find((a) => a.myorder);
          const draftRes = response.data.find((a) => a.neworder);
          const msgRes = response.data.find((a) => a.msg);
          const orderName = `${orderRes?.myorder?.body?.name || ''} 
          ${draftRes?.orderDropshipper || ''}`;
          const total = orderRes?.myorder.body.total_price || '';
          const skusOrder = orderRes?.myorder.body.line_items.map((c) => c.sku).toString() || '';
          const skusNoFound = response.data.find((a) => a.msg === 'no found');
          this.resultSync = {
            message: `${msgRes.msg} ${orderName}` || response.data[0].msg || 'message not found',
            totalOrder: total,
            skusOrder,
          };
          if (skusNoFound) this.resultSync.message += ` ${skusNoFound.skusOrder}`;
        } else {
          this.showModalSync = true;
          this.resultSync = { message: response.message || 'Unsynchronized order' };
        }
        this.loading = false;
        return 'ok';
      }).catch((err) => {
        this.errorMsg = '';
        this.showError = 0;
        me.notifyMessage('Error', err.response.data.message);
        return 'fail';
      });
    },
    getOne(id) {
      const me = this;
      if (axios.defaults.headers.common.authorization) {
        axios.get(`dropshipper/get/${id}`).then((res) => {
          me.data = res.data.body;
          me.nameDropshipper = `${me.data.name} - ${me.data.contact.name}`;
          me.nameConfig = me.data.config.shop;
          return 'ok';
        });
      }
    },
    listar() {
      const me = this;
      me.loading = true;
      this.idDropshipper = this.$route.params.id;
      me.objetos = [];
      let urlConsult = `order/no-sync/${this.idDropshipper}?times=20&unit=days`;
      const search = me.search !== '' ? me.search : false;
      if (search) urlConsult = `${urlConsult}&search=${search}`;
      if (axios.defaults.headers.common.authorization) {
        axios.get(urlConsult).then((res) => {
          me.loading = false;
          me.objetos = res.data.body;
          me.objExcel = res.data.body.map((a) => {
            const doc = { ...a };
            doc.created_at = moment(a.created_at).format('YYYY-MM-DD HH:MM');
            return doc;
          });
          me.totalItems = res.data.body.length;
          me.numberOfPages = 1;
        });
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      const empty = '';
      this.nameDropshipper = empty;
    },
    getColor(result) {
      let color = 'gray';
      if (result) color = 'green';
      else color = 'red';
      return color;
    },
    dateFormatCancelled(value) {
      let result = '';
      if (value) {
        if (value.reason && value.cancelled_at) result = value.cancelled_at;
      }
      return result;
    },
    reasonFormat(value) {
      let result = '';
      if (value) {
        if (value.reason) result = value;
      }
      return result;
    },
  },
};
</script>
