<template>
  <v-flex>
    <notify-custom :showNotify="show" :type="tipoNotify"
    :message="msgNotify" :icontext="iconNotify" :color="color">
    </notify-custom>
    <v-data-table  :server-items-length="totalItems"
      :search="search" :loading="loading" :headers="headers"
      :items="objetos"
      group-by="vendor"
      show-group-by
      class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Productos en el Dropshipper {{ name }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search"
          append-icon="search" :label="txtMessages.txtSearch"
            single-line hide-details @change="searchData(search)">
          </v-text-field>
          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar-title>
            <small>
            </small>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.image.src="{ item }">
        <td>
          <v-img lazy-src="https://picsum.photos/id/11/500/300"
          max-height="60" max-width="60"
            :src="item.image.src"></v-img>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon class="mr-2" color="blue" @click="sincProduct(item)">
          sinc. producto
        </v-btn>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="listar()">
          {{ txtMessages.txtReset }}
        </v-btn>
      </template>
    </v-data-table>
  </v-flex>
</template>
<script>
import axios from 'axios';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      name: '',
      txtMessages: langMsg,
      propsTextFooterDataTable: {
        itemsPerPageText: 'Rows per page',
        itemsPerPageAllText: 'All',
      },
      dialog: false,
      search: '',
      objetos: [],
      objExcel: [],
      originalObjetos: [],
      dialogDelete: false,
      page: 1,
      numberOfPages: 10,
      options: {},
      totalItems: 0,
      headers: [
        { text: 'image', value: 'image.src', sortable: false },
        { text: 'title', value: 'title', sortable: false },
        { text: 'vendor', value: 'vendor', sortable: false },
        { text: 'sku', value: 'sku', sortable: true },
        { text: 'price', value: 'price', sortable: true },
        { text: 'inventory', value: 'inventory_quantity', sortable: true },
        { text: 'updated at', value: 'updated_at', sortable: true },
        { text: 'actions', value: 'actions', sortable: false },
      ],
      columns: [
      ],
      // eslint-disable-next-line
      data: {},
      // --- events data ---
      loading: false,
      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',
    };
  },
  computed: {
  },
  watch: {
    dialog(val) {
      const exp = val || this.close();
      return exp;
    },
    dialogDelete(val) {
      const exp = val || this.closeDelete();
      return exp;
    },
    options: {
      handler() {
        this.listar();
      },
    },
    deep: true,
  },
  created() {
    axios.defaults.headers.common.authorization = this.$store.state.token;
    this.$store.commit('SET_LAYOUT', 'principal-layout');
    if (this.$store.state.token) this.listar();
  },

  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    listar() {
      const me = this;
      me.loading = true;
      const { page, itemsPerPage } = me.options;
      const items = itemsPerPage || 10;
      const pageNumber = page || 1;
      const search = me.search !== '' ? me.search : false;
      this.idDropshipper = this.$route.params.id;
      this.name = this.$route.params.name;
      let urlConsult = `product/skus-dropshipper/${this.idDropshipper}?limit=${items}&page=${pageNumber}`;
      if (search) urlConsult = `${urlConsult}&search=${search}`;
      axios.get(urlConsult).then((res) => {
        me.loading = false;
        me.objetos = res.data.docs;
        me.totalItems = res.data.totalDocs;
        me.numberOfPages = res.data.pages;
        me.originalObjetos = res.data.docs;
      });
    },
    searchData(search) {
      const result = this.originalObjetos?.filter((a) => a?.sku?.indexOf(search) !== -1);
      this.objetos = search && result ? result : [];
      return this.objetos;
    },
    sincProduct(item) {
      this.loading = true;
      const urlConsult = `product/sync/single/${item.product_pln_id}/${this.idDropshipper}`;
      axios.get(urlConsult).then((res) => {
        this.loading = false;
        this.notifyMessage('Info', res.data.msg);
      });
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      const empty = '';
      this.nameDropshipper = empty;
    },
  },
};
</script>
