var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.showConfirm),callback:function ($$v) {_vm.showConfirm=$$v},expression:"showConfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.txtMessages[_vm.btnOption])+" ")]),_c('v-divider',{staticClass:"mx-4"}),_c('v-spacer'),(_vm.btnOption == 'txtDeleteProductTagged')?_c('v-card-text',[_vm._v(" Se eliminaran los productos que esten creados en los dropshippers y que tengan la tag NODROPHIPPING. Una vez iniciado el proceso no se puede detener. El proceso se quedara ejecutando en 2do plano y se le notificara por correo una vez que finalice la ejecucion ")]):_vm._e(),(_vm.btnOption == 'txtInactiveProducts')?_c('v-card-text',[_vm._v(" Si todas las variantes del producto estan inactivas se archiva el producto Si son solo algunas variantes que estan inactivas se eliminan las variantes. Un sku puede estar inactivo porque fue eliminado en Shopify, o porque no esta activo en shopify, o porque tiene la tag NODROPHIPPING y antes no la tenia ")]):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.syncOption(_vm.btnOption)}}},[_vm._v(" "+_vm._s(_vm.txtMessages[_vm.btnOption])+" ")]),_c('v-spacer')],1)],1)],1),_c('notify-custom',{attrs:{"showNotify":_vm.show,"type":_vm.tipoNotify,"message":_vm.msgNotify,"icontext":_vm.iconNotify,"color":_vm.color}}),(_vm.userLogged.rol == 'dev')?_c('v-row',{staticClass:"mb-4",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.showConfirm = true;
      _vm.btnOption = 'txtDeleteProductTagged'}}},[_vm._v(" "+_vm._s(_vm.txtMessages.txtDeleteProductTagged)+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showConfirm = true;
      _vm.btnOption = 'txtInactiveProducts'}}},[_vm._v(" "+_vm._s(_vm.txtMessages.txtInactiveProducts)+" ")])],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"page":_vm.page,"pageCount":_vm.numberOfPages,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.objetos,"footer-props":_vm.propsTextFooterDataTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("PLN Variants")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"text-xs-center",attrs:{"append-icon":"search","label":_vm.txtMessages.txtSearch,"single-line":"","hide-details":""},on:{"change":function($event){return _vm.listar()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('vue-excel-xlsx',{attrs:{"data":_vm.objExcel,"columns":_vm.columns,"file-name":'logsList',"file-type":'xlsx',"sheet-name":'logs'}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"primary","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" file_download ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.txtMessages.txtExport))])])],1)],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('small')]),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer')],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('YYYY-MM-DD HH:MM'))+" ")])]}},{key:"item.updated_at",fn:function(ref){
      var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$moment(item.updated_at).format('YYYY-MM-DD HH:MM'))+" ")])]}},{key:"item.active",fn:function(ref){
      var item = ref.item;
return [_c('td',[(item.active)?_c('div',[_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" "+_vm._s(_vm.txtMessages.txtActive)+" ")])],1):_c('div',[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" "+_vm._s(_vm.txtMessages.txtInactive)+" ")])],1)])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.listar()}}},[_vm._v(" "+_vm._s(_vm.txtMessages.txtReset)+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }